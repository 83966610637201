import React, { useState, useEffect } from 'react';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { useUser } from '../UserContext';
import { Link } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
pb.autoCancellation(false);

export default function Login({ onLogin }) {
  const { isDarkMode } = useTheme();
  const { setUser } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isValid = await pb.authStore.isValid;
        if (isValid) {
          const userData = await pb.collection('users').getOne(pb.authStore.model.id);
          setUser(userData);
          onLogin(userData);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      } finally {
        setIsCheckingAuth(false);
      }
    };
    checkAuth();
  }, [setUser, onLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoggingIn(true);

    if (!username || !password) {
      setError('Por favor, complete todos los campos.');
      setIsLoggingIn(false);
      return;
    }

    if (loginAttempts >= 5) {
      setError('Demasiados intentos fallidos. Por favor, intente más tarde.');
      setIsLoggingIn(false);
      return;
    }

    try {
      const authData = await pb.collection('users').authWithPassword(username, password);
      if (authData.record) {
        const userData = await pb.collection('users').getOne(authData.record.id);
        setUser(userData);
        onLogin(userData);
        setLoginAttempts(0);
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Nombre de usuario o contraseña inválidos. Por favor, intente de nuevo.');
      setLoginAttempts(prevAttempts => prevAttempts + 1);
    } finally {
      setIsLoggingIn(false);
    }
  };

  if (isCheckingAuth) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className={`flex items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900 p-4`}>
      <div className={`w-full max-w-sm bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transition-all duration-300`}>
        <div className="p-6 sm:p-8">
          <h2 className="text-2xl font-semibold text-center text-gray-800 dark:text-white mb-6">Portal de Personal</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Nombre de usuario"
                required
                className={`w-full px-3 py-2 rounded-md border ${
                  isDarkMode
                    ? 'bg-gray-700 text-white border-gray-600'
                    : 'bg-gray-50 text-gray-800 border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300`}
              />
            </div>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña"
                required
                className={`w-full px-3 py-2 rounded-md border ${
                  isDarkMode
                    ? 'bg-gray-700 text-white border-gray-600'
                    : 'bg-gray-50 text-gray-800 border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300`}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition-colors duration-300"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5" />
                ) : (
                  <Eye className="h-5 w-5" />
                )}
              </button>
            </div>
            {error && <p className="text-red-500 text-sm text-center">{error}</p>}
            <button
              type="submit"
              disabled={isLoggingIn}
              className={`w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ${
                isLoggingIn ? 'opacity-75 cursor-not-allowed' : ''
              }`}
            >
              {isLoggingIn ? (
                <Loader2 className="w-5 h-5 animate-spin mx-auto" />
              ) : (
                'Iniciar sesión'
              )}
            </button>
          </form>
          <div className="mt-4 text-center">
            <Link
              to="/password-recovery"
              className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}